<template>
  <v-row>
    <v-card class="col-12">
      <router-link :to="{ name:'project-create' }">
        <v-btn
          color="primary"
        >
          Add New Project
        </v-btn>
      </router-link>
    </v-card>

    <v-card class="col-12 mt-6 pa-0">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">
                Name
              </th>
              <th class="text-center text-uppercase">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in projects"
              :key="i"
            >
              <td>{{ item.name }}</td>
              <td class="text-center">
                <v-btn
                  depressed
                  color="primary"
                  @click="open(item.id)"
                >
                  Edit
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-row>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      loading: false,
      projects: [],
      error: null,
    }
  },

  watch: {
    async currentProject() {
      this.fetchData()
    },
  },
  created() {
    // watch the params of the route to fetch the data again
    this.$watch(
      () => this.$route.params,
      () => {
        this.fetchData()
      },

      // fetch the data when the view is created and the data is
      // already being observed
      { immediate: true },
    )
  },
  methods: {
    fetchData() {
      this.error = null
      this.projects = []
      this.loading = true
      this.getProjects((err, projects) => {
        this.loading = false
        if (err) {
          this.error = err.toString()
        } else {
          this.projects = projects
          console.log(projects)
        }
      })
    },
    getProjects(callback) {
      axios
        .get('project/list')
        .then(response => callback(null, response.data.data))
        .catch(error => callback(error))
    },
    open(id) {
      this.$router.push({
        name: 'project-page',
        params: {
          id,
        },
      })
    },
  },
}
</script>
